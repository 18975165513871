import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { getAttributionParamForCoTeachers } from "@src/utils/attribution";

const Home = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { userID, email, hash, target, pcrID, classID, sl_onb_email } = parsedLocation;
  const attributionParams = getAttributionParamForCoTeachers();

  useRedirectHandler(() => {
    if (target === "addClass") {
      return `https://teach.classdojo.com/#/launchpad?createClass=true`;
    } else if (pcrID && target === "parentConnectionRequests") {
      return `https://teach.classdojo.com/#/launchpad?parentConnectionRequests=true&pcrId=${pcrID}`;
    } else if (target === "security") {
      return `https://teach.classdojo.com/#/launchpad?settingsTab=security`;
    } else if (target === "verifyEmail" && userID && email && hash) {
      return `https://lite.classdojo.com/emailVerification/?userId=${userID}&email=${encodeURIComponent(
        email as string,
      )}&hash=${hash}&target=teach`;
    } else if (target === "dojoIslands") {
      return `https://teach.classdojo.com/#/launchpad?dojoIslandsTab=true${classID ? `&classID=${classID}` : ""}`;
    } else {
      // sl_onb_email from the email sent to School Leaders when their request to join is approved
      if (sl_onb_email === "1") {
        attributionParams.append("sl_onb_email", "1");
      }
      return `https://teach.classdojo.com/#/?${attributionParams.toString()}`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Home;
